import React, { useState } from "react";
import './App.css';
import Login from "./components/Login";
import Register from "./components/Reg";

function App() {
  
  const [currentForm, setCurrentForm] = useState('login');

  const toggleForm = (formName) => {
    setCurrentForm(formName);
  }
  return (
    <div className="App">
      {
        currentForm === "login" ? <Login onFormSwitch={toggleForm} /> : <Register onFormSwitch={toggleForm} />
      }
    </div>
  );
}
export default App;

/*const logoText: SxProps<Theme> | undefined={
  textAlign:'center',
  fontSize: 28,
  fontWeight:'700',
  marginBottom:'15px'
}
const loginWrapper : SxProps<theme> | undefined ={
  display:'flex',
  flexDirection: 'column',
  alignItems:'center',
  justifyContent:'center'
} 
*/
