import React, { useState } from "react";

const Register = (props) => {
    const [email, setEmail] = useState('');
    const [pass, setPass] = useState('');
    const [name, setName] = useState('');
    const [lname, setLname] = useState('');
    const [cpassword, setCpass] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(email);
    }

    return (
        <div className="auth-form-container">
            <h2>Create Account</h2>
            <form className="register-form" onSubmit={handleSubmit}>
                <label htmlFor="name"></label>
                <input value={name} onChange={(e) => setName(e.target.value)} name="name" id="name" placeholder="First Name" />
                <label htmlFor="lname"></label>
                <input value={lname} onChange={(e) => setLname(e.target.value)} lname="lname" id="lname" placeholder="Last Name" />
                <label htmlFor="email"></label>
                <input value={email} onChange={(e) => setEmail(e.target.value)} type="email" placeholder="Email" id="email" name="email" />
                <label htmlFor="password"></label>
                <input value={pass} onChange={(e) => setPass(e.target.value)} type="password" placeholder="Password" id="password" name="password" />
                <label htmlFor="cpassword"></label>
                <input value={cpassword} onChange={(e) => setCpass(e.target.value)} type="password" placeholder="Confirm password" id="cpassword" name="cpassword" />
                <button type="submit">Join The Club</button>
            </form>
        </div>
    )
}

export default Register;